#issuing-card-number-container,
#issuing-card-expiry-container,
#issuing-card-cvc-container {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

#card-number-copy,
#card-expiry-copy,
#card-cvc-copy {
  height: 1rem;
  width: 1rem;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/images/copy-icon.svg');
}

#card-number-copy-success,
#card-expiry-copy-success,
#card-cvc-copy-success {
  display: none;
  height: 1rem;
  width: 1rem;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/images/checkmark.svg');
}

.card-details-close-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.card-details-wrapper {
  padding: 0 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.card-details-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 1rem;
    color: #101010;
  }
}

.card-details-title {
  font-weight: 700;
  color: #101010;
  margin: 0 0 1rem 0;
}

.card-details-asterix {
  color: #74788d;
  margin-top: 1rem;
}

.card-details-info {
  background-color: #F7F8F9;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: #101010;
  }

  div {
    width: 1rem;
    height: 1rem;
    border-radius: 15px;
    padding: 10px;
    background-color: #DB5F2C;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    i {
      color: #fff;
    }
  }
}

.card-details-verification-input {
  background-color: #F7F8F9;
  padding: 10px 15px;
  border-radius: 15px;

  .input-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 500;
      color: #101010;
      margin: 0;
    }

    span {
      color: #fd625e;
    }
  }

  input {
    padding: 0;
    border: none;
    outline: none;
    font-size: 16px;
    color: #007AFF;
    background-color: transparent;

    &::placeholder {
      color: #74788d;
    }
  }
}

.card-details-button {
  background-color: #717CBC;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 400;
  width: 100%;
  font-size: 1rem;

  &:disabled {
    opacity: 0.5;
  }
}

.card-details {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  background-color: #E8EAED;
  color: #101010;
}

.card-details-container {
  border-radius: 15px;
  background-color: #F7F8F9;
  padding: 0 15px;
}

.card-details-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px rgba(16,16,16, 0.1);

  &:last-child {
    border-bottom: none;
  }
}

.card-details-row-text {
  flex: 1;

  p {
    margin: 0;
  }

  strong {
    font-weight: 500;
  }
}

.error-or-loading {
  padding: 10px;
  text-align: center;
}